import { useEffect, useRef } from "react"

const Loading = ({ percent = 0 }) => {
  const barRef = useRef()
  useEffect(() => {
    if (barRef.current) {
      barRef.current.style.width = percent + "%"
    }
  }, [percent])

  useEffect(() => {
    let _percent = 0
    const interval = setInterval(() => {
      if (_percent < 80) {
        _percent += 10
        if (barRef.current) {
          barRef.current.style.width = _percent + "%"
        }
      }
    }, 100)

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  return (
    <div
      className={`fixed w-full h-full ease-linear bg-defaultBg z-[10000] flex flex-col justify-center items-center`}
    >
      <div className="h-[200px]">
        <img src={`/assetss/banner/Logo/Logo_new.webp`} alt={``} className={`w-[200px]`} />
      </div>
      <div className="w-[150px] ipad:w-[300px] h-2 bg-gray-200 rounded-full dark:bg-gray-700 mt-5">
        <div
          className="bg-yellow-s text-xs transition-all h-2 font-medium text-blue-100 text-center rounded-full flex justify-center items-center"
          ref={barRef}
        >
          {/*<p className={`text-center text-title font-extrabold`}>{percent > 0 && percent.toFixed(2)}%</p>*/}
        </div>
      </div>
    </div>
  )
}

export default Loading
