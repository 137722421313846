import { PlaySpine } from "assetss/vendors/spine-init"
import { useEffect } from "react"
import { isMobile } from "react-device-detect"
import "styles/heroes.scss"

const TokenHls = ({ section }) => {
  const playAnimation = () => {
    PlaySpine("icon-hls", "/assetss/tokens/animation/Coin", "hls", {
      onSuccess: () => {},
      onFailed: () => {},
    })
  }

  useEffect(() => {
    if (window.spine && section === 5 && !isMobile) {
      playAnimation()
    }
  }, [section])

  return (
    <div
      className={`relative w-full overflow-hidden min-h-screen ipad:h-screen bg-no-repeat bg-token-s bg-cover bg-token flex flex-col items-center justify-center`}
    >
      <img src={`/assetss/line_1.png`} alt={``} className="absolute top-0" />

      <div className="relative w-full px-5">
        <div className="absolute w-full top-16 left-1/2 -translate-x-1/2 flex flex-col items-center space-y-2">
          <img src="assetss/tokens/hls_token.png" alt="" className="h-[24px]" />
          <div className="flex flex-col items-center text-title text-center">
            <div>HLS is our second in-game token with unlimited supply.</div>
            <div className="text-sm">
              Players earn HLS via game activities and spend the token to upgrade to higher rankings
              in the game.
            </div>
          </div>
        </div>
        <div className="pt-32">
          <div className="flex flex-col">
            <div className="flex px-10 justify-center">
              <div className="hidden xl:flex flex-col w-[310px] justify-center items-center">
                <img src="/assetss/tokens/Hala/icon_game.png" alt="" />
                <div className="flex flex-col items-center text-title text-center">
                  <div className="text-xl font-extrabold">How to earn?</div>
                  <div>PvE Campaign</div>
                  <div>Tower Conquest</div>
                  <div>Daily Quest</div>
                  <div>AFK</div>
                </div>
              </div>
              <div className="flex flex-col md:h-[384px] gap-[30px]">
                <div className={`flex justify-center gap-5 flex-auto items-center`}>
                  <div className="hidden xl:block">
                    <img src={`/assetss/tokens/arrow.png`} alt={``} />
                  </div>
                  <div className="relative w-[200px] h-[200px] lg:w-[310px] lg:h-[310px] flex items-center justify-center pr-9">
                    <canvas id="icon-hls" className="w-full h-full"></canvas>
                    <div className="absolute left-0 -bottom-20 flex items-center">
                      <img src={`/assetss/tokens/hls_table.png`} alt={``} />
                    </div>
                  </div>
                  <div className="hidden xl:block">
                    <img src={`/assetss/tokens/arrow.png`} alt={``} />
                  </div>
                </div>
              </div>
              <div className="hidden xl:flex flex-col p-5  w-[310px] justify-center items-center">
                <img src="/assetss/tokens/Hala/money.png" alt="" />
                <div className="flex flex-col items-center text-title text-center">
                  <div className="text-xl font-extrabold">Where to use?</div>
                  <div>Unlock Heroes Slot</div>
                  <div>Level Up Heroes</div>
                  <div>Exchange</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TokenHls
