export const timelineData = [
  {
    time: "2021-2022",
    id: "1",
    description: "Development",
    items: [
      {
        completed: true,
        title: "Project Start, Game Concept Design, Landing page",
      },
      {
        completed: true,
        title:
          "Web3 PC Test  version (Daily Quest, Tower Conquest), Marketplace, Ascend & Summoning features",
      },
      {
        completed: true,
        title: "Web 3 Mobile test version, PvP feature",
      },
      {
        completed: true,
        title: "Web2 test version",
      },
    ],
  },
  {
    time: "2023 Q1 - Q2",
    id: "2",
    description: "Growth",
    items: [
      {
        completed: false,
        title: "Gear Crafting, Advanced PvP Battle",
      },
      {
        completed: false,
        title: "Clan Battle",
      },
      {
        completed: false,
        title: "Clan Boss raid, Gear Forge",
      },
      {
        completed: false,
        title: "Web 2 game release",
      },
      {
        completed: false,
        title: "Seed & Private Sale, Starter Pack Sale",
      },
      {
        completed: false,
        title: "IDO, token listing on DEX and CEX",
      },
      {
        completed: false,
        title: "Web 3 Game Release",
      },
    ],
  },
  {
    time: "2023 Q3 - Q4",
    id: "3",
    description: "Evolution",
    items: [
      {
        completed: false,
        title: "Land Feature: resource mining, forging exclusive gears",
      },
      {
        completed: false,
        title: "Land training house",
      },
      {
        completed: false,
        title: "Land NFT Sale",
      },
      {
        completed: false,
        title: "Staking and Governance, Full game storyline",
      },
      {
        completed: false,
        title: "2nd game concept design",
      },
    ],
  },
  {
    time: "2024",
    id: "4",
    description: "Universe expansion",
    items: [
      {
        completed: false,
        title: "New RPG Game Development (Heroes land Universe expansion)",
      },
      {
        completed: false,
        title: "1st Game’s Land market, Land battle features",
      },
      {
        completed: false,
        title: "New NFT sale",
      },
    ],
  },
]
