import TransitionArray from "./Spring"
const Investor = ({ check }) => {
  return (
    <div
      className={`relative w-full min-h-screen bg-investor__partner bg-cover bg-no-repeat bg-center pb-10 flex flex-col items-center justify-center ipad:px-32`}
    >
      <img className="absolute top-0" src={`/assetss/line_1.png`} alt={``} />
      <div className={`mt-20`} />

      <div className={`flex flex-col justify-center items-center gap-8`}>
        {check === 9 && (
          <>
            <div className="relative z-10 flex justify-center w-full flex-wrap">
              <div className="flex flex-col items-center">
                <TransitionArray className="z-10" delay={200} transform="translate3d(0, -50%, 0)">
                  <img className="h-[24px]" src="/assetss/investor_partner/investors.png" alt="" />
                </TransitionArray>
                <div className="flex flex-col sm:flex-row gap-10 lg:gap-[50px] mt-5 justify-center items-center">
                  <TransitionArray delay={800} transform="translate3d(-50%, 0px, 0px)">
                    <a href="https://skynetvietnam.com" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[180px] 2xl:max-w-[300px] cursor-pointer"
                        src="/assetss/investor_partner/logo_skychain.png"
                        alt=""
                      />
                    </a>
                  </TransitionArray>
                  <TransitionArray delay={800} transform="translate3d(50%, 0px, 0px)">
                    <a href="https://sotanext.com" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[280px] 2xl:max-w-[406px] cursor-pointer"
                        src="/assetss/investor_partner/logo_sotanext.png"
                        alt=""
                      />
                    </a>
                  </TransitionArray>
                </div>
              </div>
            </div>
            <div className="relative z-10 flex justify-center w-full flex-col items-center space-y-6 md:space-y-0">
              <TransitionArray className="z-10" delay={200} transform="translate3d(0, -50%, 0)">
                <img className="h-[24px]" src="/assetss/investor_partner/partners.png" alt="" />
              </TransitionArray>

              <TransitionArray className="z-10" delay={1000}>
                <div className="flex flex-col items-center space-y-6 md:space-y-0">
                  <div className="flex flex-col md:flex-row gap-10 lg:gap-[50px] justify-center items-center">
                    <a href="https://www.creoengine.com/" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[200px] 2xl:max-w-[356px] cursor-pointer"
                        src="/assetss/investor_partner/creoengine.png"
                        alt=""
                      />
                    </a>

                    <a href="https://vendettadao.com/" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[80px] 2xl:max-w-[120px] cursor-pointer"
                        src="/assetss/investor_partner/vendetta.png"
                        alt=""
                      />
                    </a>

                    <a href="https://www.binance.com/" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[250px] 2xl:max-w-[400px] cursor-pointer"
                        src="/assetss/investor_partner/binancenft.png"
                        alt=""
                      />
                    </a>

                    <a href="https://fizen.io/" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[120px] 2xl:max-w-[200px] cursor-pointer"
                        src="/assetss/investor_partner/fizen.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="flex flex-col md:flex-row gap-10 lg:gap-[50px] justify-center items-center">
                    <a href="https://gamefi.org/hub/heroes-land" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[200px] 2xl:max-w-[356px] cursor-pointer"
                        src="/assetss/investor_partner/gamefi.png"
                        alt=""
                      />
                    </a>

                    <a href="https://twitter.com/ar_gaming_guild" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[120px] 2xl:max-w-[250px] cursor-pointer"
                        src="/assetss/investor_partner/agg.png"
                        alt=""
                      />
                    </a>
                    <a href="https://twitter.com/HundredDAO" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[150px] 2xl:max-w-[250px] cursor-pointer"
                        src="/assetss/investor_partner/hundao.png"
                        alt=""
                      />
                    </a>

                    <a href="https://horizonland.app/" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[250px] 2xl:max-w-[400px] cursor-pointer"
                        src="/assetss/investor_partner/horizon.png"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="flex flex-col md:flex-row gap-10 lg:gap-[50px] justify-center items-center">
                    <a href="https://myria.com/" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[150px] 2xl:max-w-[250px] cursor-pointer"
                        src="/assetss/investor_partner/myria.png"
                        alt=""
                      />
                    </a>

                    <a href="https://twitter.com/x8guild " target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[150px] 2xl:max-w-[250px] cursor-pointer"
                        src="/assetss/investor_partner/x8g.png"
                        alt=""
                      />
                    </a>

                    <a href="https://yeehagames.com/" target="_blank" rel="noreferrer">
                      <img
                        className="max-w-[120px] 2xl:max-w-[200px] cursor-pointer"
                        src="/assetss/investor_partner/yeeha.png"
                        alt=""
                      />
                    </a>

                    <a
                      href="https://chainplay.gg/games/heroes-land/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="max-w-[150px] 2xl:max-w-[250px] cursor-pointer"
                        src="/assetss/investor_partner/chainplay.png"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </TransitionArray>
            </div>
            <div className="relative z-10 flex justify-center w-full flex-col items-center gap-5">
              <TransitionArray className="z-10" delay={200} transform="translate3d(0, -50%, 0)">
                <img className="h-[24px]" src="/assetss/investor_partner/audited_by.png" alt="" />
              </TransitionArray>
              <TransitionArray className="z-10" delay={1000}>
                <img
                  className="max-w-[200px] 2xl:max-w-[320px] cursor-pointer"
                  src="/assetss/investor_partner/certiklogo.png"
                  alt=""
                />
              </TransitionArray>
            </div>
            <div className="hidden lg:block absolute bottom-0 left-0">
              <TransitionArray delay={800} transform="translate3d(-50%, 0px, 0px)">
                <img
                  className="max-h-[calc(100vh)]"
                  src="/assetss/investor_partner/cungthu.png"
                  alt=""
                />
              </TransitionArray>
            </div>
            <div className="hidden lg:block absolute bottom-0 left-0">
              <TransitionArray
                delay={800}
                className="mixBlendMode"
                transform="translate3d(-50%, 0px, 0px)"
              >
                <img src="/assetss/investor_partner/SMOKER_1.png" alt="" />
              </TransitionArray>
            </div>
            <div className="hidden lg:block absolute bottom-0 right-0">
              <TransitionArray delay={800} transform="translate3d(50%, 0px, 0px)">
                <img
                  className="max-h-[calc(100vh-100px)]"
                  src="/assetss/investor_partner/hoimau.png"
                  alt=""
                />
              </TransitionArray>
            </div>
            <div className="hidden lg:block absolute bottom-0 right-0">
              <TransitionArray
                delay={800}
                className="mixBlendMode"
                transform="translate3d(50%, 0px, 0px)"
              >
                <img src="/assetss/investor_partner/SMOKER_2.png" alt="" />
              </TransitionArray>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Investor
