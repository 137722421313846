import React from "react"
// import Carousel from "components/carousel";
import Layout from "layouts"
import { MantineProvider } from "@mantine/core"

const Root = () => {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colors: {
          title: Array(10).fill("#f90"),
        },
      }}
    >
      <div>
        <div className={`w-full`}>
          <Layout />
        </div>
      </div>
    </MantineProvider>
  )
}

export default Root
