import Modal, { useModal } from "components/modal"

const GameStory = () => {
  const [visibleMobile, onShowMobile, onHideMobile] = useModal()

  return (
    <div
      className={`relative w-full h-full bg-no-repeat bg-center bg-cover bg-gameStory overflow-hidden`}
    >
      <Modal
        visible={visibleMobile}
        onHide={onHideMobile}
        className={`ipad:w-[70vw] ipad:h-[40vw] w-[90vw] h-[50vw]`}
      >
        <div className={`relative bg-transparent w-full h-full`}>
          <img
            src="/assetss/close.png"
            alt={``}
            className={`absolute right-0 top-0 w-[20px] z-10`}
            onClick={onHideMobile}
          />
          <iframe
            className="absolute top-0 lef-0 w-full h-full"
            src="https://www.youtube.com/embed/K9gxFvlcxl8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
      <img
        src={`/assetss/line_1.png`}
        alt={``}
        className="absolute top-0 w-full flex justify-center"
      />
      <div className={`absolute top-20 z-20 w-full flex justify-center`}>
        <img src="assetss/game-story/game_story.png" alt="" className="h-[32px]" />
      </div>
      <div className={`flex flex-col md:flex-row w-full justify-center items-center md:mt-0`}>
        <div className={`md:w-1/2 px-8 font-frodi flex flex-col space-y-4`}>
          <p className={`text-title text-base`}>
            Heroes Land is a series of games that combine an entertaining Match-3 puzzle and the
            typical antagonistic properties of RPG genre.
          </p>
          <p className={`text-title text-base`}>
            Heroes Land aims to create a balance between fun gameplay and income earning with the
            unique dual -gameplay model combining both traditional and blockchain gameplay modes.
          </p>
        </div>
        <div className={`relative w-full h-full md:-ml-48 flex items-end`}>
          <img
            src="/assetss/game-story/video_frame.png"
            alt=""
            onClick={onShowMobile}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  )
}

export default GameStory
