import { useTransition, animated, config } from 'react-spring'
import { useState} from 'react'
function Mount({children, transform, className, delay}) {

  const [show, set] = useState(false)
  const transitions = useTransition(true, {
    from: { opacity: 0,  transform: transform },
    enter: { opacity: 1, transform: "translate3d(0%, 0px, 0px)"  },
    leave: { opacity: 1, transform: "translate3d(0%, 0px, 0px)" },
    reverse: show,
    delay: delay || 200,
    config: config.molasses,
    onRest: () => set(!show),
  })
  return transitions(
    (styles, item) => item && <animated.div className={className??""} style={styles}>{children}</animated.div>
  )
}
export default Mount;