import React, { useRef, useState } from "react"
import { Link } from "react-scroll"
import "styles/header.scss"
import { useOnClickOutside } from "hooks/helpers"

const Drawer = ({ showDropdown, hideDrawer }) => {
  const [optical, setOptical] = useState(0)
  const ref = useRef()

  useOnClickOutside(ref, () => {
    hideDrawer()
  })

  return (
    <div
      className={`font-gilroy font-extrabold w-full items-center h-screen flex justify-end z-[10000] md:text-xl showDropdown ${
        showDropdown ? "fade" : ""
      }`}
    >
      <div
        ref={ref}
        className={`flex flex-col w-[75%] md:w-1/2 items-center bg-drawer h-full space-y-5 pt-10`}
      >
        <div className={`w-full text-center`}>
          <Link
            to="banner"
            spy={true}
            smooth={true}
            // offset={-80}
            duration={500}
          >
            <span
              className={`font-extrabold ${
                optical === 0 ? "text-title" : "text-white"
              } text-center`}
            >
              Home
            </span>
          </Link>
        </div>

        <img src={`/assetss/line_1.png`} alt={``} />
        <div className={`w-full text-center`}>
          <Link
            to="gameStory"
            spy={true}
            smooth={true}
            // offset={-80}
            duration={500}
          >
            <span className={`font-extrabold ${optical === 2 ? "text-title" : "text-white"}`}>
              Game Introduction
            </span>
          </Link>
        </div>
        <img src={`/assetss/line_1.png`} alt={``} />

        <div className={`w-full text-center`}>
          <Link
            to="roadmap"
            spy={true}
            smooth={true}
            // offset={-80}
            duration={500}
          >
            <span className={`font-extrabold ${optical === 3 ? "text-title" : "text-white"}`}>
              Roadmap
            </span>
          </Link>
        </div>
        <img src={`/assetss/line_1.png`} alt={``} />
        <div className={`w-full text-center`}>
          <Link
            to="coreTeams"
            spy={true}
            smooth={true}
            // offset={-80}
            duration={500}
          >
            <span
              className={`font-extrabold ${
                optical === 1 ? "text-title" : "text-white"
              } text-center`}
            >
              Team
            </span>
          </Link>
        </div>
        <img src={`/assetss/line_1.png`} alt={``} />

        {/* <a
          target="_blank"
          href="https://account-testnet.heroesland.io/referral"
          className="text-[#00A3FF] text-xs 2xl:text-lg font-extrabold"
          rel="noreferrer"
        >
          <button className="px-4 bg-black/30 border border-[#00A3FF] rounded-[6px] flex items-center justify-center gap-2 h-[30px]">
            Referral Contest
          </button>
        </a> */}
        <a
          target="_blank"
          href="https://heroesland.medium.com/%EF%B8%8F-how-to-claim-5-free-heroes-to-play-in-beta-ab22545eebe8"
          className="text-[#c61a09] text-xs 2xl:text-lg font-extrabold"
          rel="noreferrer"
        >
          <button className="px-4 bg-black/30 border border-[#c61a09] rounded-[6px] flex items-center justify-center gap-2 h-[30px]">
            Beginners' Guide
          </button>
        </a>

        <div className={`w-[60%] mt-3`}>
          <img src={`/assetss/banner/Logo/Logo_new.png`} alt={``} />
        </div>
      </div>
    </div>
  )
}

export default Drawer
