import { useSpring, config } from "react-spring"

export default function useRoadmapForegroundAnimation() {
  const animation = useSpring({
    from: {
      opacity: 0,
      y: 200,
    },
    to: {
      opacity: 1,
      y: 0,
    },
    delay: 500,
    config: {
      ...config.molasses,
      duration: 300,
    },
  })
  return animation
}
