import { isEmpty } from "lodash"

export function PlaySpine(
  idCanvas,
  spineName1,
  animeSpine1,
  { onSuccess, onFailed },
  align = { preferHeight: false },
) {
  var canvas
  var gl
  var shader
  var batcher
  var mvp = new window.spine.webgl.Matrix4()
  var assetManager
  var skeletonRenderer

  var lastFrameTime
  var spineboy
  //var spinePath = "/content/img/spin/";
  var spineName
  var animeSpine
  var pause = false
  spineName = spineName1
  animeSpine = animeSpine1
  canvas = typeof idCanvas === "string" ? document.getElementById(idCanvas) : idCanvas
  function initSpine() {
    // Setup canvas and WebGL context. We pass alpha: false to canvas.getContext() so we don't use premultiplied alpha when
    // loading textures. That is handled separately by PolygonBatcher.

    //canvas.width = window.innerWidth;
    //canvas.height = window.innerHeight;
    var config = { alpha: true }
    gl = canvas.getContext("webgl", config) || canvas.getContext("experimental-webgl", config)
    if (!gl) {
      alert("WebGL is unavailable.")
      return
    }

    // Create a simple shader, mesh, model-view-projection matrix, SkeletonRenderer, and AssetManager.
    shader = window.spine.webgl.Shader.newTwoColoredTextured(gl)
    batcher = new window.spine.webgl.PolygonBatcher(gl)
    mvp.ortho2d(0, 0, canvas.width - 1, canvas.height - 1)
    skeletonRenderer = new window.spine.webgl.SkeletonRenderer(gl)
    assetManager = new window.spine.webgl.AssetManager(gl)

    // Tell AssetManager to load the resources for each skeleton, including the exported .skel file, the .atlas file and the .png
    // file for the atlas. We then wait until all resources are loaded in the load() method.
    assetManager.loadBinary(spineName + ".skel")
    assetManager.loadTextureAtlas(spineName + ".atlas")
    requestAnimationFrame(load)
    if (!isEmpty(assetManager.errors)) {
      onFailed && onFailed()
    }
  }

  function load() {
    // Wait until the AssetManager has loaded all resources, then load the skeletons.
    if (assetManager.isLoadingComplete()) {
      onSuccess && onSuccess()
      spineboy = loadSpineboy(animeSpine, true)
      updateCanvas()
      lastFrameTime = Date.now() / 1000
      requestAnimationFrame(render) // Loading is done, call render every frame.
    } else {
      requestAnimationFrame(load)
    }
  }

  function loadSpineboy(initialAnimation, premultipliedAlpha) {
    // Load the texture atlas from the AssetManager.
    var atlas = assetManager.get(spineName + ".atlas")

    // Create a AtlasAttachmentLoader that resolves region, mesh, boundingbox and path attachments
    var atlasLoader = new window.spine.AtlasAttachmentLoader(atlas)

    // Create a SkeletonBinary instance for parsing the .skel file.
    var skeletonBinary = new window.spine.SkeletonBinary(atlasLoader)

    // Set the scale to apply during parsing, parse the file, and create a new skeleton.
    skeletonBinary.scale = 1
    var skeletonData = skeletonBinary.readSkeletonData(assetManager.get(spineName + ".skel"))
    var skeleton = new window.spine.Skeleton(skeletonData)
    var bounds = calculateSetupPoseBounds(skeleton)

    // Create an AnimationState, and set the initial animation in looping mode.
    var animationStateData = new window.spine.AnimationStateData(skeleton.data)
    var animationState = new window.spine.AnimationState(animationStateData)
    animationState.setAnimation(0, initialAnimation, true)

    // Pack everything up and return to caller.
    return {
      skeleton: skeleton,
      state: animationState,
      bounds: bounds,
      premultipliedAlpha: premultipliedAlpha,
    }
  }

  function calculateSetupPoseBounds(skeleton) {
    skeleton.setToSetupPose()
    skeleton.updateWorldTransform()
    var offset = new window.spine.Vector2()
    var size = new window.spine.Vector2()
    skeleton.getBounds(offset, size, [])
    return { offset: offset, size: size }
  }

  function render() {
    if (pause) return false
    var now = Date.now() / 1000
    var delta = now - lastFrameTime
    lastFrameTime = now

    // Update the MVP matrix to adjust for canvas size changes
    resize()

    gl.clearColor(0, 0, 0, 0)
    //gl.backgroundColor = "#00000000";
    gl.clear(gl.COLOR_BUFFER_BIT)

    // Apply the animation state based on the delta time.
    var skeleton = spineboy.skeleton
    var state = spineboy.state
    var premultipliedAlpha = spineboy.premultipliedAlpha
    state.update(delta)
    state.apply(skeleton)
    skeleton.updateWorldTransform()

    // Bind the shader and set the texture and model-view-projection matrix.
    shader.bind()
    shader.setUniformi(window.spine.webgl.Shader.SAMPLER, 0)
    shader.setUniform4x4f(window.spine.webgl.Shader.MVP_MATRIX, mvp.values)

    // Start the batch and tell the SkeletonRenderer to render the active skeleton.
    batcher.begin(shader)
    skeletonRenderer.premultipliedAlpha = premultipliedAlpha
    skeletonRenderer.draw(batcher, skeleton)
    batcher.end()

    shader.unbind()

    requestAnimationFrame(render)
  }

  function updateCanvas() {
    canvas.height = "auto"
    canvas.width = "auto"
    if (!canvas.classList.contains("h-full")) {
      canvas.classList.add("h-full")
    }
    if (!canvas.classList.contains("w-full")) {
      canvas.classList.add("w-full")
    }
    // Calculations to center the skeleton in the canvas.
    var bounds = spineboy.bounds
    var w = canvas.clientWidth
    var h = canvas.clientHeight
    var parentH = canvas.parentElement.clientHeight
    var parentW = canvas.parentElement.clientWidth
    if (!align.preferHeight && bounds.size.x > bounds.size.y) {
      canvas.height =
        Math.min((w / bounds.size.x) * bounds.size.y, parentH) + 50 + (align.addHeight || 0)
      canvas.width = w + 50 + (align.addWidth || 0)
    } else {
      canvas.width = Math.max((h / bounds.size.y) * bounds.size.x, parentW) + (align.addWidth || 0)
      canvas.height = h + 50 + (align.addHeight || 0)
    }

    if (canvas.classList.contains("h-full")) {
      canvas.classList.remove("h-full")
    }
    if (canvas.classList.contains("w-full")) {
      canvas.classList.remove("w-full")
    }
  }

  function resize() {
    // Calculations to center the skeleton in the canvas.
    var bounds = spineboy.bounds
    var centerX = bounds.offset.x + bounds.size.x / 2
    var centerY = bounds.offset.y + bounds.size.y / 2
    var scaleX = bounds.size.x / canvas.width
    var scaleY = bounds.size.y / canvas.height
    var scale = Math.max(scaleX, scaleY)
    if (scale < 1) scale = 1
    var width = canvas.width * scale
    var height = canvas.height * scale

    var startX = align.left || centerX - width / 2
    var startY = align.bottom || centerY - height / 2

    mvp.ortho2d(startX, startY, width, height)
    gl.viewport(0, 0, canvas.width, canvas.height)
  }
  initSpine()

  function pauseSpine() {
    pause = true
  }
  return pauseSpine
}
