import { listConnect } from "layouts/Footer"
import { useState, useEffect } from "react"

const JoinOur = ({ section }) => {
  const [display, setDisplay] = useState(true)
  const [show, setShow] = useState(true)

  const toggle = () => {
    setShow((_show) => !_show)
  }

  useEffect(() => {
    if (section) setShow(false)
    else setShow(true)

    if (section === 10) setDisplay(false)
    else setDisplay(true)
  }, [section])

  return (
    <div
      className={`hidden ipad:flex items-center fixed right-0 top-1/2 -translate-y-1/2 z-[100] cursor-pointer ${
        display ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className="bg-header bg-opacity-75 border border-title border-r-0 rounded rounded-r-none px-2 py-3"
        onClick={toggle}
      >
        {show ? (
          <img src="/assetss/social_hide.png" alt="" />
        ) : (
          <img src="/assetss/social_show.png" alt="" />
        )}
      </div>
      <div
        className={`flex space-y-4 flex-col transition-all duration-300 bg-header bg-opacity-75 border border-title border-r-0 rounded rounded-r-none px-2 py-3 ${
          !show ? "w-0 !p-0 border-0" : "w-[60px]"
        }`}
      >
        {listConnect.map((item, idx) => (
          <a key={idx} href={item.link} target={`_blank`} rel={`noreferrer noopener`}>
            <img src={item.icon} alt={``} className={`w-[45px] transition-all`} />
          </a>
        ))}
      </div>
    </div>
  )
}

export default JoinOur
