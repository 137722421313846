import { useSpring, config } from "react-spring"

export default function useRoadmapTimelineAnimation({ delay = 0, from = {}, to = {} }) {
  const animation = useSpring({
    from: {
      opacity: 0,
      ...from,
    },
    to: {
      opacity: 1,
      ...to,
    },
    delay: 600 + delay,
    config: {
      ...config.molasses,
      duration: 500,
    },
  })
  return animation
}
