import { PlaySpine } from "assetss/vendors/spine-init"
import { useEffect } from "react"
import { isMobile } from "react-device-detect"
import "styles/heroes.scss"
const TokenHala = ({ section }) => {
  const playAnimation = () => {
    PlaySpine("icon-hala", "/assetss/tokens/animation/Coin", "hala", {
      onSuccess: () => {},
      onFailed: () => {},
    })
  }

  useEffect(() => {
    if (window.spine && section === 4 && !isMobile) {
      playAnimation()
    }
  }, [section])

  return (
    <div
      className={`relative w-full overflow-hidden min-h-screen ipad:h-screen bg-no-repeat bg-token-s bg-cover bg-token flex flex-col items-center justify-center`}
    >
      <img src={`/assetss/line_1.png`} alt={``} className="absolute top-0" />

      <div className="relative w-full px-5">
        <div className="absolute w-full top-16 left-1/2 -translate-x-1/2 flex flex-col items-center space-y-2 p-5">
          <img src="assetss/tokens/hala_token.png" alt="" className="h-[24px]" />
          <div className="flex flex-col items-center text-title text-center">
            <div>HALA is the main in-game and governance token with limited supply.</div>
            <div>
              HALA token is required for game NFT payment and key game activities such as ascension
              and summoning.
            </div>
            <div>A portion of the token is used to reward players.</div>
          </div>
        </div>
        <div className="pt-36">
          <div className="flex flex-col">
            <div className="flex px-10 justify-center">
              <div className="hidden xl:flex flex-col w-[310px] justify-center items-center">
                <img src="/assetss/tokens/Hala/icon_game.png" alt="" />
                <div className="flex flex-col items-center text-title text-center">
                  <div className="text-xl font-extrabold">How to earn?</div>
                  <div>Reward in PvP, Clan</div>
                  <div>Reward Treasury</div>
                  <div>Community Event</div>
                  {/* <div>Sell NFT on Marketplace</div> */}
                </div>
              </div>
              <div className="flex flex-col md:h-[384px] gap-[30px]">
                <div className={`flex justify-center gap-5 flex-auto items-center`}>
                  <div className="hidden xl:block">
                    <img src={`/assetss/tokens/arrow.png`} alt={``} />
                  </div>
                  <div className="relative w-[200px] h-[200px] lg:w-[310px] lg:h-[310px] flex items-center justify-center pr-9">
                    <canvas id="icon-hala" className="w-full h-full"></canvas>
                    <div className="absolute left-0 -bottom-20 flex items-center">
                      <img src={`/assetss/tokens/hala_table.png`} alt={``} />
                    </div>
                  </div>
                  <div className="hidden xl:block">
                    <img src={`/assetss/tokens/arrow.png`} alt={``} />
                  </div>
                </div>
              </div>
              <div className="hidden xl:flex flex-col p-5  w-[310px] justify-center items-center">
                <img src="/assetss/tokens/Hala/money.png" alt="" />
                <div className="flex flex-col items-center text-title text-center">
                  <div className="text-xl font-extrabold">Where to use?</div>
                  <div>Payment for game NFTs</div>
                  <div>Ascension</div>
                  <div>Summoning</div>
                  <div>Staking</div>
                  <div>Governance</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TokenHala
