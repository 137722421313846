export default function Button({ children, className }) {
  return (
    <div>
      <button
        className={`px-2 border flex items-center justify-center w-[100px] 2xl:w-[130px] h-[30px] rounded-[35px] bg-[#000000] opacity-[.85] ${
          className ?? ""
        }`}
      >
        <div className={`textShadowButton text-sm font-semibold ${className ?? ""}`}>
          {children}
        </div>
      </button>
    </div>
  )
}
