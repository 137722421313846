import ReactFlipCard from "reactjs-flip-card"
import { EffectCoverflow, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "styles/game-future.scss"

const GameFeatures = () => {
  return (
    <div className="relative w-full min-h-screen ipad:h-screen bg-no-repeat bg-cover bg-center ipad:bg-cover bg-gameFeature">
      <img src={`/assetss/line_1.png`} alt={``} className={`absolute top-0`} />
      <div className="flex flex-col min-h-screen justify-center py-20">
        <div className="absolute top-20 w-full flex justify-center">
          <img src="assetss/game-features/game_features.png" alt="" className="h-[32px]" />
        </div>
        <div className="flex justify-center text-title px-16 lg:px-0">
          <Swiper
            className="!py-20 max-h-1/2"
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
            }}
            coverflowEffect={{
              rotate: 25,
              stretch: 0,
              depth: 50,
              slideShadows: true,
            }}
            pagination={{
              el: ".feature-slider-pagination",
              clickable: true,
            }}
            effect="coverflow"
            centeredSlides={true}
            slideToClickedSlide={true}
            modules={[Pagination, EffectCoverflow]}
          >
            <SwiperSlide>
              <ReactFlipCard
                containerCss="w-full h-full"
                direction="vertical"
                frontComponent={<img src="/assetss/game-features/pvp.png" alt="" />}
                backComponent={<img src="/assetss/game-features/pvp_back.png" alt="" />}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ReactFlipCard
                containerCss="w-full h-full"
                direction="vertical"
                frontComponent={<img src="/assetss/game-features/clan.png" alt="" />}
                backComponent={<img src="/assetss/game-features/clan_back.png" alt="" />}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ReactFlipCard
                containerCss="w-full h-full"
                direction="vertical"
                frontComponent={<img src="/assetss/game-features/pve.png" alt="" />}
                backComponent={<img src="/assetss/game-features/pve_back.png" alt="" />}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ReactFlipCard
                containerCss="w-full h-full"
                direction="vertical"
                frontComponent={<img src="/assetss/game-features/land.png" alt="" />}
                backComponent={<img src="/assetss/game-features/land_back.png" alt="" />}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ReactFlipCard
                containerCss="w-full h-full"
                direction="vertical"
                frontComponent={<img src="/assetss/game-features/activity.png" alt="" />}
                backComponent={<img src="/assetss/game-features/activity_back.png" alt="" />}
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="feature-slider-pagination flex gap-3 justify-center"></div>
      </div>
    </div>
  )
}

export default GameFeatures
