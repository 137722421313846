export const listConnect = [
  { icon: "/assetss/icons/facebook.png", link: "https://www.facebook.com/HeroesLandP2E/" },
  { icon: "/assetss/icons/twitter.png", link: "https://twitter.com/HeroesLand_P2E" },
  { icon: "/assetss/icons/telegram.png", link: "https://t.me/HeroesLand_P2E_ANN" },
  { icon: "/assetss/icons/discord.png", link: "https://discord.gg/VpmVD3sn4n" },
  { icon: "/assetss/icons/medium.png", link: "https://heroesland.medium.com/" },
]

export const listLink = [
  { name: "HOME", link: "" },
  { name: "WHITEPAPER", link: "https://whitepaper.heroesland.io" },
  { name: "PRIVACY POLICY", link: "https://whitepaper.heroesland.io/policy/privacy-policy" },
  { name: "TERMS OF USE", link: "https://whitepaper.heroesland.io/policy/terms-of-use" },
  { name: "DISCLAIMER", link: "https://whitepaper.heroesland.io/policy/disclaimer" },
  // { name: "FAQ", link: "" },
  // { name: "SUPPORT", link: "" },
]

export const listLink2 = [
  { name: "MARKETPLACE", link: "https://whitepaper.heroesland.io/market-overview/market-overview" },
  { name: "PRIVACY POLICY", link: "https://whitepaper.heroesland.io/policy/privacy-policy" },
  { name: "TERMS OF USE", link: "https://whitepaper.heroesland.io/policy/terms-of-use" },
  { name: "DISCLAIMER", link: "https://whitepaper.heroesland.io/policy/disclaimer" },
]

const Footer = ({ spineStatus }) => {
  return (
    <footer
      id="footer"
      className={`section fp-auto-height relative w-full min-h-screen bg-transparent !flex flex-col items-center justify-center mx-auto p-8 md:p-16`}
    >
      <img src={`/assetss/line_1.png`} alt={``} className="absolute top-0 w-full left-0 " />
      <div
        className={`mx-auto container w-full ipad:w-full flex flex-col justify-center items-center`}
      >
        <div className="w-full flex flex-col ipad:flex-row space-y-5 justify-around items-center text-title">
          <div className="order-3 ipad:order-1 w-full flex justify-center items-center">
            <img
              src="/assetss/banner/Logo/Logo_new.webp"
              alt=""
              className="w-full h-auto max-w-[200px]"
            />
          </div>
          <div className="order-1 ipad:order-2  w-full h-full font-extrabold">
            <div className="flex flex-0 flex-col space-y-5 items-center">
              <div className="relative w-full flex justify-center">
                <img src="/assetss/footer_frame.png" alt="" width="400" />
                <div className="absolute -top-1 w-full h-full flex flex-col justify-center items-center space-y-1">
                  <div className="text-white uppercase font-extrabold text-lg">
                    JOIN OUR COMMUNITY
                  </div>
                  <div className="flex space-x-2">
                    {listConnect.map((item, idx) => (
                      <a key={idx} href={item.link} target={`_blank`} rel={`noreferrer noopener`}>
                        <img src={item.icon} alt={``} className={`transition-all`} width="36" />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              {listLink.map((item, idx) => (
                <a
                  key={idx}
                  href={item.link}
                  target={`_blank`}
                  rel={`noreferrer noopener`}
                  className={`text-white font-extrabold`}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="order-2 invisible ipad:order-3  w-full flex flex-col space-y-2 justify-center items-center">
            <div className="text-white uppercase font-extrabold">JOIN OUR COMMUNITY</div>
            <div className="flex space-x-1">
              {listConnect.map((item, idx) => (
                <a key={idx} href={item.link} target={`_blank`} rel={`noreferrer noopener`}>
                  <img src={item.icon} alt={``} className={`transition-all`} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`text-white font-phagspab text-xs mt-10 text-center flex flex-col items-center justify-center gap-1`}
      >
        <span>Copyright © Heroes Land. All Rights Reserved</span>
      </div>
    </footer>
  )
}

export default Footer
