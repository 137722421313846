/* eslint-disable no-unused-vars */
import ButtonComponent from "components/button"
import Drawer from "layouts/Drawer"
import { useLayoutEffect, useState } from "react"

const Header = ({ fullpageApi, section }) => {
  const [opticalBounding, setOpticalBounding] = useState({ width: 0, left: -1000 })
  const [showDropdown, setShowDropdown] = useState(false)
  // const [comingSoon, setComingSoon] = useState("Beta Test")
  const listMenu = [
    { label: "Game Introduction", onClick: () => fullpageApi.moveTo(2), section: 1 },
    { label: "Roadmap", onClick: () => fullpageApi.moveTo(8), section: 7 },
    { label: "Team", onClick: () => fullpageApi.moveTo(9), section: 8 },
    { label: "Whitepaper", onClick: () => window.open("https://whitepaper.heroesland.io/") },
    "",
  ]

  const hideDrawer = () => {
    setShowDropdown(false)
  }

  useLayoutEffect(() => {
    const activeLink = document.querySelector("#desktop-menu span.text-title")
    if (activeLink && activeLink.offsetParent) {
      const bounding = activeLink.getBoundingClientRect()
      setOpticalBounding({ width: bounding.width, left: bounding.x })
    }
    if (![1, 7, 8].includes(section)) {
      setOpticalBounding({ width: 0, left: -1000 })
    }
  }, [section])

  const showMenuDesktop = () => {
    return listMenu.map((item, index) => (
      <div onClick={item.onClick} key={index}>
        <span
          className={`font-extrabold ${
            section === item.section ? "text-title" : "text-white"
          } cursor-pointer`}
        >
          {item.label}
        </span>
      </div>
    ))
  }

  return (
    <header id="header" className="z-[999]">
      <Drawer showDropdown={showDropdown} hideDrawer={hideDrawer} />
      <div className={`font-gilroy font-extrabold`} id="desktop-menu">
        <img
          alt=""
          src={"/assetss/light-text.png"}
          className={`fixed bottom-4 duration-300 z-[999] hidden ipad:block`}
          style={{
            height: "2px",
            width: opticalBounding?.width + 30,
            left: (opticalBounding?.left ?? 0) - 15,
            top: 45,
          }}
        />
        <div
          className={`hidden ipad:flex w-full justify-between items-center z-20 fixed top-0 px-10 ${
            section > 0 ? "bg-header" : ""
          }`}
        >
          <div className={`flex gap-[30px] py-3`}>
            <button
              onClick={() => {
                fullpageApi.moveTo(1)
              }}
            >
              <img src={`/assetss/logoheader_1.png`} alt={``} className={`w-[80px] h-[35px]`} />
            </button>
            <div className="flex flex-row text-[12px] 2xl:text-lg font-bold gap-3 xl:gap-7 2xl:gap-10 bg-clip-text items-center select-none relative">
              {showMenuDesktop()}
              {/* <div>
                <a
                  href={
                    isMacOs
                      ? "https://images.heroesland.io/download/heroesland_testnet_mac.app.zip"
                      : isAndroid
                      ? "https://images.heroesland.io/download/heroesland_testnet_android.apk"
                      : "https://images.heroesland.io/download/heroesland_testnet_windows.zip"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="px-4 bg-black/30 border border-[#FF9900] rounded-[6px] flex items-center justify-center gap-2 h-[30px]">
                    <span className="text-[#FF9900] text-xs 2xl:text-lg font-extrabold">
                      Download Beta
                    </span>
                    <img className="hidden xl:block w-[15px]" src="/assetss/sword_2.png" alt="" />
                  </button>
                </a>
              </div> */}
              {/* <div>
                <button className="px-4 bg-black/30 border border-[#00A3FF] rounded-[6px] flex items-center justify-center gap-2 h-[30px]">
                  <a
                    target="_blank"
                    href="https://account-testnet.heroesland.io/referral"
                    className="text-[#00A3FF] text-xs 2xl:text-lg font-extrabold"
                    rel="noreferrer"
                  >
                    Referral Contest
                  </a>
                </button>
              </div> */}
              <div>
                <button className="px-4 bg-black/30 border border-[#c61a09] rounded-[6px] flex items-center justify-center gap-2 h-[30px]">
                  <a
                    target="_blank"
                    href="https://heroesland.medium.com/%EF%B8%8F-how-to-claim-5-free-heroes-to-play-in-beta-ab22545eebe8"
                    className="text-[#c61a09] text-xs 2xl:text-lg font-extrabold"
                    rel="noreferrer"
                  >
                    Beginners' Guide
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-3 xl:gap-5">
            {/* <ButtonComponent className="text-[#FF8A00] text-xs border-[#FF8A00] buttonHoverMarket">
              <a
                href="https://market-testnet.heroesland.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Marketplace
              </a>
            </ButtonComponent> */}

            <ButtonComponent className="text-[#00A3FF] text-xs border-[#00A3FF] buttonHoverAccount">
              <a
                href="https://account-testnet.heroesland.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Account
              </a>
            </ButtonComponent>
          </div>
        </div>

        <div
          className={`ipad:hidden bg-brown flex justify-between items-center fixed z-[300] w-full py-2`}
        >
          <div
            className={`px-2`}
            onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
          >
            <img src={`/assetss/logoheader_1.png`} alt={``} className={`w-24`} />
          </div>
          <div className={`flex space-x-3 px-2 items-center`}>
            <div>
              <img
                src={`/assetss/img.png`}
                alt={``}
                className={`w-6 h-5`}
                onClick={() => setShowDropdown(!showDropdown)}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
