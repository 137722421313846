import { IconBrandAndroid, IconBrandApple, IconBrandWindows } from "@tabler/icons"
import BannerLogo from "assetss/images/banner/logo.png"
import { listConnect } from "layouts/Footer"
import "styles/banner.scss"

const Banner = ({ spineStatus }) => {
  const DownloadButton = ({ children }) => (
    <div className="text-title border border-title px-3 py-1 rounded-md bg-black flex items-center gap-2 opacity-70 hover:opacity-100 transition">
      {children}
    </div>
  )

  return (
    <div className={`relative banner-background bg-banner bg-cover bg-left-bottom overflow-hidden`}>
      <div className="flex w-full h-screen justify-center ipad:justify-start items-center overflow-hidden">
        <div className="w-[80%] md:w-[60%] ipad:w-[40%] pb-[100px] sm:pb-0 h-[90%] m-10 z-20 flex flex-col items-center justify-end ipad:justify-center">
          <img src={BannerLogo} alt={``} />
          <DownloadButton>
            <div className="py-3 text-title font-frodi text-xl text-center">
              An Unique Combination of Match-3 and RPG gameplay, empowered by blockchain technology.
            </div>
          </DownloadButton>
          <div className="py-6 text-title text-2xl uppercase">Download Beta version</div>
          <div className="flex flex-col md:flex-row space-y-5 md:space-x-5 md:space-y-0 items-center cursor-pointer">
            <a
              href={"https://images.heroesland.io/download/heroesland_testnet_android.apk"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadButton>
                <IconBrandAndroid size={32} />
                <span className="text-xl">Android</span>
              </DownloadButton>
            </a>
            <a
              href="https://images.heroesland.io/download/heroesland_testnet_mac.app.zip"
              target="_blank"
              rel="noopener noreferrer"
              className="flex"
            >
              <DownloadButton>
                <IconBrandApple size={32} />
                <span className="text-xl">MacOS</span>
              </DownloadButton>
            </a>
            <a
              href={"https://images.heroesland.io/download/heroesland_testnet_windows.zip"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadButton>
                <IconBrandWindows size={32} />
                <span className="text-xl">Windows</span>
              </DownloadButton>
            </a>
          </div>
          <div className={`ipad:hidden bg-transparent z-[100] pt-10`}>
            <div className={`flex space-x-2  pl-2  py-3 rounded transition-all`}>
              {listConnect.map((item, idx) => (
                <a key={idx} href={item.link} target={`_blank`} rel={`noreferrer noopener`}>
                  <img
                    src={item.icon}
                    alt={``}
                    className={`w-[45px] hover:w-[55px] transition-all`}
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 lg:left-auto lg:-right-[100px] w-full h-full flex items-end justify-end -mr-[400px] lg:-mr-[250px] ipad:-mr-[100px]">
          <canvas
            id="bannerIntro"
            className="w-full min-h-screen hidden lg:block"
            style={{ minHeight: "calc(100vh + 200px)" }}
          />
          <div
            className="h-screen w-screen flex sm:!bg-right bg-cover lg:hidden"
            style={{
              backgroundImage: "url(/assetss/banner/bg_landing/bg_landing-idle_0.png)",
              backgroundPositionX: "calc(min(-100vw, -800px))",
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Banner
