export const heroes = [
  {
    type: "beast",
    heroes: [
      {
        name: "Zetylios",
        description: "Zetylios - The Heavenly Guard Commander ",
        rarity: 5,
        class: ["light", "berserker"],
        skill: 5,
      },
      {
        name: "Sigark",
        description: "Sigark - Child of the Tiger",
        rarity: 3,
        class: ["fire", "assassin"],
        skill: 2,
        scale: 0.85,
      },
      {
        name: "Kaarol",
        description: "Kaarol - The Bull Warrior",
        rarity: 1,
        class: ["nature", "warrior"],
        skill: 1,
        scale: 0.9,
      },
    ],
  },
  {
    type: "human",
    heroes: [
      {
        name: "Jogo",
        description: "Jogo - The Marksman",
        rarity: 1,
        class: ["light", "archer"],
        skill: 3,
        scale: 0.9,
      },
      {
        name: "Lilna",
        description: "Lilna - The Hidden Dagger",
        rarity: 2,
        class: ["shadow", "assassin"],
        skill: 3,
        scale: 0.8,
      },
      {
        name: "Vedora",
        description: "Vedora - The Sorcerer's Apprentice",
        rarity: 1,
        class: ["shadow", "wizard"],
        skill: 4,
        scale: 0.8,
      },
    ],
  },
  {
    type: "elf",
    heroes: [
      {
        name: "Shine",
        description: "Shine - Master of Arrows",
        rarity: 5,
        class: ["nature", "archer"],
        skill: 4,
        scale: 0.8,
        align: { addWidth: 100 },
      },
      {
        name: "Henena",
        description: "Henena - Ruler of magical powers",
        rarity: 4,
        class: ["fire", "wizard"],
        skill: 4,
        scale: 0.75,
        align: { addHeight: 120 },
      },
      {
        name: "Leah",
        description: "Leah - The noble sword",
        rarity: 1,
        class: ["nature", "berserker"],
        skill: 4,
        scale: 0.7,
        align: { addHeight: 150 },
      },
    ],
  },
  {
    type: "orc",
    heroes: [
      {
        name: "Nizver",
        description: "Nizver - The orc rebel",
        rarity: 2,
        class: ["ice", "assassin"],
        skill: 4,
        scale: 0.95,
      },
      {
        name: "Magroz",
        description: "Magroz - Leader of Tenpal",
        rarity: 3,
        class: ["ice", "warrior"],
        skill: 4,
        scale: 1,
      },
      {
        name: "Magent",
        description: "Magent - Warrior of the Orcs",
        rarity: 1,
        class: ["fire", "knight"],
        skill: 4,
        scale: 0.85,
      },
    ],
  },
  {
    type: "god",
    heroes: [
      {
        name: "Gordil",
        description: "Gordil - The Thunder Mage",
        rarity: 3,
        class: ["light", "wizard"],
        skill: 4,
        scale: 0.9,
      },
      {
        name: "Nez",
        description: "Nez - The sword of Light ",
        rarity: 2,
        class: ["ice", "berserker"],
        skill: 4,
        scale: 1,
        align: { addWidth: 100 },
      },
      {
        name: "Altiz",
        description: "Altiz - Goddess of warriors",
        rarity: 2,
        class: ["fire", "knight"],
        skill: 4,
        scale: 0.85,
        align: { addWidth: 100 },
      },
    ],
  },
  {
    type: "undead",
    heroes: [
      {
        name: "Baektom",
        description: "Baektom - The Passion for skills",
        rarity: 1,
        class: ["ice", "assassin"],
        skill: 4,
        scale: 0.75,
      },
      {
        name: "Zynkii",
        description: "Zynkii - Queen of the Night",
        rarity: 4,
        class: ["shadow", "knight"],
        skill: 4,
        scale: 1,
      },
      {
        name: "Harley",
        description: "Harley - The Moonlight Archer",
        rarity: 3,
        class: ["nature", "archer"],
        skill: 4,
        scale: 0.95,
      },
    ],
  },
  {
    type: "demon",
    heroes: [
      {
        name: "Rarfel",
        description: "Rarfel - The protector of Dahlith",
        rarity: 2,
        class: ["light", "archer"],
        skill: 4,
        scale: 0.9,
      },
      {
        name: "Stekaz",
        description: "Stekaz - The humble demon",
        rarity: 1,
        class: ["light", "berserker"],
        skill: 4,
        scale: 0.75,
        align: { addWidth: 150 },
      },
      {
        name: "Jeemil",
        description: "Jeemil - The force of Night",
        rarity: 4,
        class: ["shadow", "knight"],
        skill: 4,
        scale: 0.85,
      },
    ],
  },
]
