import { Timeline } from "@mantine/core"
import { timelineData } from "data/roadmap"
import useRoadmapForegroundAnimation from "hooks/useRoadmapForegroundAnimation"
import useRoadmapTimelineAnimation from "hooks/useRoadmapTimelineAnimation"
import { animated } from "react-spring"

const MileStone = ({ milestone, position }) => {
  const pointAnimation = useRoadmapTimelineAnimation({
    delay: position * 300,
    from: { scale: 0 },
    to: { scale: 1 },
  })
  const arrowUpAnimation = useRoadmapTimelineAnimation({
    delay: position * 350,
    from: { scale: 0 },
    to: { scale: 1 },
  })
  const milestoneAnimation = useRoadmapTimelineAnimation({
    delay: (position + 1) * 350,
    from: {},
    to: {},
  })
  const arrowRightAnimation = useRoadmapTimelineAnimation({
    delay: (position + 1) * 300,
    from: { scale: 0 },
    to: { scale: 1 },
  })
  return (
    <animated.div className="relative flex items-center space-x-2">
      <div className="relative">
        <animated.img
          src="/assetss/roadmap/new/point.png"
          alt=""
          className="origin-center"
          style={pointAnimation}
        />
      </div>
      <div className="relative">
        <animated.img
          src="/assetss/roadmap/new/arrow_right.png"
          alt=""
          width="auto"
          className="origin-left"
          style={arrowRightAnimation}
        />
      </div>
      <div className="absolute left-0 -top-2 -translate-y-full w-full flex flex-col !mx-1">
        <div className="relative space-y-2">
          <animated.div className="h-[88px]" style={milestoneAnimation}>
            <div className="font-bold text-lg">{milestone.time}</div>
            <div className="text-title">Milestone {milestone.id}:</div>
            <div className="text-title capitalize">{milestone.description}</div>
          </animated.div>
          <animated.img
            src="/assetss/roadmap/new/arrow_up.png"
            alt=""
            width="auto"
            className="origin-bottom"
            style={arrowUpAnimation}
          />
        </div>
      </div>
      <animated.div
        className="absolute left-0 -bottom-2 translate-y-full w-full flex flex-col !mx-1"
        style={milestoneAnimation}
      >
        <div className="relative space-y-2">
          {milestone.items &&
            milestone.items.map((item) => (
              <div className="flex items-start space-x-2" key={item.title}>
                <img
                  src="/assetss/roadmap/new/tick.png"
                  alt=""
                  className={item.completed ? "visible pt-1" : "invisible"}
                />
                <div className="text-xs">{item.title}</div>
              </div>
            ))}
        </div>
      </animated.div>
    </animated.div>
  )
}

const RoadmapForeground = () => {
  const animation = useRoadmapForegroundAnimation()
  return (
    <animated.img
      src="/assetss/roadmap/new/foreground.png"
      alt=""
      className="z-0"
      style={animation}
    ></animated.img>
  )
}

const Roadmap = ({ section }) => {
  return (
    <div className="relative bg-roadmapMobile ipad:bg-roadmap bg-no-repeat bg-cover bg-top pb-10 min-h-screen ipad:pb-0 ipad:h-screen overflow-hidden">
      <img src="/assetss/line_1.png" alt="" className="w-full" />
      <div className="absolute left-0 top-20 w-full flex justify-center">
        <img src="assetss/roadmap/roadmap_title.png" alt="" className="h-[24px]" />
      </div>
      {section === 7 && (
        <div className="hidden md:flex w-full h-full justify-center items-end">
          <div className="relative w-full flex justify-center">
            <RoadmapForeground />
            <div className="z-10 absolute top-0 -translate-y-1/2 flex text-white space-x-2">
              {timelineData.map((milestone, index) => (
                <MileStone milestone={milestone} position={index} key={index} />
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="flex md:hidden justify-center flex-col items-center mt-32 px-8">
        <Timeline
          active={3}
          bulletSize={8}
          lineWidth={2}
          color="title"
          classNames={{ itemTitle: "text-title" }}
        >
          {timelineData.map((milestone) => (
            <Timeline.Item
              key={milestone.id}
              title={`Milestone ${milestone.id}: ${milestone.description}`}
              color="title"
            >
              {milestone.items &&
                milestone.items.map((item) => (
                  <div className="flex items-start space-x-2" key={item.title}>
                    <img
                      src="/assetss/roadmap/new/tick.png"
                      alt=""
                      className={item.completed ? "visible pt-1" : "filter saturate-0 pt-1"}
                    />
                    <div className="text-xs text-white">{item.title}</div>
                  </div>
                ))}
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
  )
}

export default Roadmap
