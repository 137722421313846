import "styles/tokenomic.scss"

const Block = ({ section }) => {
  return (
    <div
      className={`flex justify-center gap-10 lg:gap-20 items-center w-full ${
        section === 6 ? "fadeIn" : "fadeOut"
      } ipad:py-5 flex-col ipad:flex-row ipad:max-h-[75%] px-16`}
    >
      <img
        src={`/assetss/tokenomic/tokennomic.png`}
        alt={`tokennomic`}
        className={`ipad:max-w-[40%] max-h-full`}
      />
      <div className="ipad:max-w-[30%] flex flex-col items-center gap-5">
        <h1 className="font-bold text-title text-2xl">HALA</h1>
        <img src="/assetss/tokenomic/chart.png" alt="chart" className="w-full" />
      </div>
    </div>
  )
}

const Tokenomic = ({ section }) => {
  return (
    <div
      className={`relative w-full ipad:h-screen bg-no-repeat bg-center bg-cover bg-tokenomic flex flex-col items-center overflow-hidden pb-10`}
    >
      <img src={`/assetss/line_1.png`} alt={``} />
      <div className="mt-16 mb-10">
        <img src="/assetss/tokenomic/tokenomics_title.png" alt="chart" className="h-[32px]" />
      </div>
      <Block section={section} />
    </div>
  )
}

export default Tokenomic
