import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useRef, useState } from "react"

export const useModal = () => {
  const [visible, setVisible] = useState(false)

  const onShow = () => setVisible(true)
  const onHide = () => setVisible(false)

  return [visible, onShow, onHide]
}

export default function Modal({
  visible,
  onHide,
  children,
  className,
  opacity,
  shadow,
  hidden,
  bg,
}) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-[999] inset-0 overflow-y-auto ${hidden && "hidden sm:block"}`}
        initialFocus={cancelButtonRef}
        onClose={onHide}
      >
        <div
          ref={cancelButtonRef}
          className="flex items-center justify-center min-h-screen bg-inactive pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo={opacity ?? "opacity-100"}
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0  bg-opacity-75 transition-opacity bg- ${opacity ?? ""} ${
                bg ?? "bg-stone-800"
              }`}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`inline-block align-middle bg-transparent text-left overflow-hidden transform transition-all sm:align-middle ${
                className ?? ""
              } ${shadow || "shadow-xl"}`}
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
