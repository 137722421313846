import {useEffect, useState} from "react"

export function useSpineSize(calculateSize) {
  const [size, setSize] = useState(0)

  useEffect(() => {
    let resizeTimer;
    setSize(calculateSize())
    window.addEventListener("resize", () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(function () {
        setSize(calculateSize());
      }, 250)
    });
  }, [])

  return size;
}

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};
